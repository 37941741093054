/*style Cenco Pryme
V1
*/

@import './bootstrap.min.css';
@import './font-awesome.min.css';

@font-face {
  font-family: 'isidorablack';
  src: url('../fonts/latinotype_-_isidora-black-webfont.woff2') format('woff2'),
    url('../fonts/latinotype_-_isidora-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'isidorabold';
  src: url('../fonts/latinotype_-_isidora-bold-webfont.woff2') format('woff2'),
    url('../fonts/latinotype_-_isidora-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'isidoramedium';
  src: url('../fonts/latinotype_-_isidora-medium-webfont.woff2') format('woff2'),
    url('../fonts/latinotype_-_isidora-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'isidorasemibold';
  src: url('../fonts/latinotype_-_isidora-semibold-webfont.woff2') format('woff2'),
    url('../fonts/latinotype_-_isidora-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  overflow-x: hidden !important;
}

.home {
  //width: 100%;

  body {
    background-color: #f3f3f6;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-style: normal;
    line-height: 1.5;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Source Sans Pro', sans-serif;
  }
  .text-y-prime {
    color: #ec1c24;
  }
  .text-b-prime {
    color: #383838;
  }
  .f12 {
    font-size: 12px !important;
  }
  .f14 {
    font-size: 14px !important;
  }
  .f16 {
    font-size: 16px !important;
  }
  .f18 {
    font-size: 18px !important;
  }
  .f21 {
    font-size: 21px !important;
  }
  .f24 {
    font-size: 24px !important;
  }
  .f26 {
    font-size: 26px !important;
  }
  .f28 {
    font-size: 28px !important;
  }

  /*Botones*/
  .btn-primary {
    background: #ec1c24;
    color: #ffffff;
    border: 2px solid #ec1c24;
    border-radius: 24px;
  }
  .btn-primary-validate {
    background: #ec1c24;
    color: #ffffff;
    border: 2px solid #ec1c24;
  }
  .btn-outline-primary-validate {
    background-color: white;
    border-color: #ec1c24;
    color: #ec1c24;
  }
  .btn-outline-primary-validate:hover {
    background-color: #ec1c24;
    border-color: #e9ecef;
    color: #e9ecef;
  }
  .btn-primary.disabled {
    color: #212529;
    /*background-color: #ffc107;
    border-color: #ffc107;*/
    background-color: #e9ecef;
    border-color: #dee2e6;
    cursor: no-drop;
    pointer-events: auto !important;
  }
  .btn-primary.disable:hover {
    background-color: #e9ecef;
    border-color: #dee2e6;
    cursor: no-drop;
    pointer-events: auto !important;
  }
  .btn-primary:hover,
  .btn-primary.focus,
  .btn-primary:focus {
    background: #fff;
    color: #ec1c24;
    border: 2px solid #ec1c24;
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    background-color: #ec1c24;
    color: #383838;
    border-color: #ec1c24;
    border-radius: 24px;
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: #ec1c24;
    color: #383838;
    border-color: #ec1c24;
    border-radius: 24px;
  }

  .btn-prime {
    background-color: #ec1c24;
    border-color: #ec1c24;
    border-radius: 30px;
    color: #fff;
  }
  .btn-prime:hover {
    background-color: #f4b12e;
    border-color: #f4b12e;
  }
  .btn-outline-prime {
    color: #383838;
    border: 2px solid #ec1c24;
    border-radius: 30px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
  }
  .btn-outline-prime:hover {
    background-color: #ec1c24;
    border-color: #ec1c24;
    color: #fff;
  }
  .btn-extended {
    padding: 0.375rem 1.75rem;
  }
  .btn-sound {
    height: 50px;
    width: 50px;
    line-height: 38px;
    text-align: center;
    border-radius: 24px;
    margin-left: 15px;
  }
  /*s*/
  .section_padding_100_0 {
    padding-top: 100px;
    padding-bottom: 0px;
  }
  .section_padding_100 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .section_padding_100_50 {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .section_padding_100_70 {
    padding-top: 100px;
    padding-bottom: 70px;
  }
  .section_padding_50 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section_padding_50_20 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
  .section_padding_150 {
    padding-top: 150px;
    padding-bottom: 150px;
  }
  .section_padding_200 {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  .section_padding_0_100 {
    padding-top: 0;
    padding-bottom: 100px;
  }
  .section_padding_70 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .section_padding_0_50 {
    padding-top: 0;
    padding-bottom: 50px;
  }
  .mt-15 {
    margin-top: 15px;
  }
  .mt-30 {
    margin-top: 30px;
  }
  .mt-40 {
    margin-top: 40px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mt-100 {
    margin-top: 100px;
  }
  .mt-150 {
    margin-top: 150px;
  }
  .mr-15 {
    margin-right: 15px;
  }
  .mr-30 {
    margin-right: 30px;
  }
  .mr-50 {
    margin-right: 50px;
  }
  .mr-100 {
    margin-right: 100px;
  }
  .mb-15 {
    margin-bottom: 15px;
  }
  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  .mb-100 {
    margin-bottom: 100px;
  }
  .ml-15 {
    margin-left: 15px;
  }
  .ml-30 {
    margin-left: 30px;
  }
  .ml-50 {
    margin-left: 50px;
  }
  .ml-100 {
    margin-left: 100px;
  }

  .heading-text > p {
    font-size: 16px;
  }

  .section-heading > h2 {
    color: #383838;
    font-size: 38px;
    margin-bottom: 30px;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
    font-weight: bold;
  }
  .section-heading > p {
    font-size: 24px;
    font-weight: 300;
  }
  .section-heading {
    margin-bottom: 60px;
  }
  .line-shape-white,
  .line-shape {
    width: 80px;
    height: 2px;
    background-color: #ec1c24;
    margin-top: 15px;
  }
  .line-shape {
    margin-left: calc(50% - 40px);
  }
  /* --------------------------
:: 2.0 Header Area CSS
-------------------------- */
  .header_area {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0px;
    padding: 0 4%;
    /*background-color: #0053c0;*/
  }
  .menu_area .navbar-brand {
    font-size: 46px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1;
    padding: 0;
  }
  .menu_area .navbar-brand:hover,
  .menu_area .navbar-brand:focus {
    color: #fff;
  }
  .menu_area .navbar-brand img {
    width: 130px;
  }
  .menu_area {
    position: relative;
    z-index: 2;
  }
  .menu_area #nav .nav-link {
    color: #fff;
    display: block;
    font-size: 16px;
    font-weight: 300;
    border-radius: 30px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    padding: 35px 15px;
  }
  .menu_area nav ul li > a:hover {
    color: #fb397d;
    text-decoration: none;
  }
  .sing-up-button {
    text-align: right;
  }
  .sing-up-button > a {
    background: #ec1c24;
    color: #ffffff;
    font-weight: 500;
    display: inline-block;
    border: 2px solid #ec1c24;
    height: 50px;
    min-width: 178px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px;
  }
  .sing-up-button > a:hover {
    background-color: #fff;
    border: 2px solid #ec1c24;
    color: #ec1c24;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    text-decoration: none;
  }
  /*.header_area .navbar-light .navbar-toggler {
    color: rgba(250,250,250,.5);
    border-color: rgba(250,250,250,.5);
}*/

  /*header_area.sticky*/
  .logo-b {
    display: none;
  }
  .header_area.sticky .logo-b {
    display: block;
  }
  .header_area.sticky .logo-a {
    display: none;
  }

  .header_area.sticky {
    background-color: #fff;
    -webkit-box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    height: 70px;
    position: fixed;
    top: 0px;
    z-index: 99;
  }

  .header_area.sticky .menu_area .navbar-brand {
    font-size: 36px;
  }

  .header_area.sticky .menu_area #nav .nav-link {
    padding: 23px 15px;
    color: #383838;
  }
  .header_area.sticky .navbar {
    padding: 0;
  }
  .navbar-light .navbar-toggler {
    color: rgba(250, 250, 250, 0.5);
    border-color: rgba(250, 250, 250, 0.5);
    border: 2px solid;
    background: #fff;
  }

  .header_area.sticky .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
  }

  /* --------------------------
:: 3.0 Wellcome Area CSS
-------------------------- */

  .wellcome_area {
    background-color: #e39a75;
    background-image: url(../img/LINK-COMPRESS.jpg);
    //background-image: url(../img/header-desktop-anniversary.png);
    height: 100vh;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: normal;
  }

  .wellcome_area:after {
    position: absolute;
    //width: 100%;
    left: 0;
    height: 2px;
    background-color: #fff;
    content: '';
    bottom: 0;
    z-index: 2;
  }
  .wellcome_area .wellcome-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  .wellcome_area .wellcome-video video {
    background-size: contain;
    background-position: center center;
    background-color: black;
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-height: 100%;
    min-width: 100%;
  }

  .welcome-thumb {
    width: 45%;
    position: absolute;
    bottom: -130px;
    right: 85px;
    z-index: 9;
  }
  .wellcome_area .video-overlay {
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    letter-spacing: 0;
    background: #383838;
    z-index: 1;
    opacity: 0.65;
  }

  .welcome-thumb img {
    height: auto;
    width: 100%;
  }
  .wellcome-heading > h2 {
    font-size: 1.7rem;
    color: #ffffff;
    font-weight: 200;
    position: relative;
    z-index: 3;
    font-family: 'isidoramedium', 'Source Sans Pro', sans-serif;
    letter-spacing: -1px;
  }
  .wellcome-heading > h2 span {
    font-size: 3.5rem;
    font-weight: 700;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
    color: #383838;
  }

  .get-start-area .email {
    background: #9572e8;
    height: 50px;
    max-width: 260px;
    border: none;
    border-radius: 24px;
    padding: 0px 15px;
  }
  .get-start-area a {
    position: relative;
    z-index: 3;
  }

  .get-start-area .submit {
    background-color: #ec1c24;
    color: #ffffff;
    font-weight: 500;
    display: inline-block;
    border: 2px solid #ec1c24;
    height: 50px;
    min-width: 167px;
    line-height: 46px;
    text-align: center;
    border-radius: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .get-start-area .submit:hover {
    background: #fff;
    color: #ec1c24;
    border: 2px solid #ec1c24;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
    text-decoration: none;
  }

  .wellcome-heading > p {
    /*color: #fff;*/
    color: #383838;
    font-weight: 300;
    font-size: 1.2rem;
    z-index: 3;
    position: relative;
    span {
      color: #ec1c24;
      font-weight: bold;
    }
  }
  .wellcome-heading .logos-prime {
    position: relative;
    z-index: 3;
  }
  .wellcome-heading .logos-prime li img {
    width: 50px;
  }

  .wellcome-heading {
    margin-bottom: 100px;
  }
  /*pagians extras*/
  .page-heading > h2 {
    font-size: 3rem;
    font-weight: 700;
    color: #ffffff;
    position: relative;
    z-index: 3;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
    letter-spacing: -1px;
    text-align: center;
  }
  .page_area {
    background-color: #d6cfb0;
    background-image: url(../img/HEADER5.png);
    height: 450px;
    position: relative;
    z-index: 1;
    //background-position: center center;
    background-size: cover;
    background-position-y: -146%;
    //background-blend-mode: multiply;
  }
  .text_condition {
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 15px;
  }
  .color-y {
    color: #ec1c24;
  }

  /*special areas*/
  .special-area {
    background-image: url(../img/jumbop.png);
    background-size: 700px;
    background-position: 63vw -80px;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
  .secction-2 {
    background-color: #f8f6f1;
    background-image: url(../img/bg-06.png);
    //background-image: url(../img/bg-2.png);
    background-size: 750px;
    background-repeat: no-repeat;
    background-position: -155px -0%;
    background-attachment: fixed;
    background-blend-mode: multiply;
    z-index: 1;
  }
  .logo-nav-subscription {
    width: 7%;
    position: absolute;
    left: 7%;
  }

  .grupo-frutas-2 {
    position: absolute;
    width: 24%;
    right: -21%;
    bottom: -13%;
  }

  .elemento-manzana {
    position: absolute;
    top: -4%;
    width: 20%;
    z-index: 0;
    left: -7%;
  }

  .elemento-uvas {
    position: absolute;
    top: -4%;
    width: 20%;
    z-index: 0;
    right: 0%;
  }

  .elemento-naranja {
    width: 20%;
    position: absolute;
    bottom: -3%;
    left: -9%;
    z-index: 0;
  }

  .elemento-fresa {
    width: 20%;
    position: absolute;
    bottom: 5%;
    left: -9%;
    z-index: 0;
  }

  .elemento-carne {
    position: absolute;
    width: 20%;
    right: 5%;
    bottom: -3%;
  }
  .margen-bottom {
    border-bottom: 2px solid #c7c7c7;
  }

  .elemento-naranja-cortada {
    position: absolute;
    width: 20%;
    right: 0;
    bottom: -3%;
  }

  .elemento-moras {
    position: absolute;
    width: 20%;
    right: -3%;
    transform: rotate(143deg);
    bottom: 2%;
  }

  .align_li_to_icon {
    display: flex;
    align-items: center;
  }

  .logo_wong_step {
    width: 20%;
    margin-left: -1%;
  }

  .secction-3 {
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: 700px;
    background-position: -400px -10%;
    background-blend-mode: multiply;
  }
  .secction-4 {
    //background-color: #d6cfb0;
    background-image: url(../img/LANDING-2-2.png), url(../img/FONDO.png);
    background-repeat: no-repeat;
    //background-position: center bottom;
    /*background-attachment: fixed;*/
    background-blend-mode: unset;
    background-size: cover;
    background-repeat: round;
  }
  .secction-5 {
    background-color: #f8f6f1;
    //background-image: url(../img/elementos-01.png);
    background-image: url(../img/faq-izq.png), url(../img/faq-der.png);
    background-repeat: no-repeat, no-repeat;
    background-size: 310px, 310px;
    background-position: 0px 1%, 100% 100%;
    background-blend-mode: multiply, multiply;
    background-attachment: fixed;
  }
  .section-anniversary {
    height: auto; 
  }
  .section-anniversary img{
    width: 100%; 
  }
  .anniversary-section-desktop{
    display: block;
  }
  .anniversary-section-mobile{
    display: none;
  }
  @media (max-width: 768px) {
    .anniversary-section-desktop{
      display: none;
    }
    .anniversary-section-mobile{
      display: block;
  }
  }
  .background-frutas {
    background-color: #f8f6f1;
    //background-image: url(../img/elementos-01.png);
    background-image: url(../img/elemento_1.png), url(../img/elemento_2.png),
      url(../img/elemento_3.png), url(../img/elemento_4.png), url(../img/elemento_5.png),
      url(../img/elemento_6.png), url(../img/elemento_7.png);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    //background-size: 310px, 310px, 310px, 310px, 310px, 310px, 310px;
    background-size: 30%, 30%, 30%, 30%, 30%, 30%, 30%;
    background-position: -18% -9%, 118% -5%, -26% 110%, -34% 94%, 114% 110%, 115% 99%, 119% 84%;
    background-blend-mode: unset;
  }

  .content-frutas {
    position: relative;
  }

  .content-frutas .faq-uvas {
    position: absolute;
    left: -8%;
    top: -5%;
    transform: rotate(-32deg);
    width: 20%;
  }

  .content-frutas .faq-manzana {
    position: absolute;
    right: -4%;
    top: -3%;
    transform: rotate(60deg);
    width: 20%;
  }

  .content-frutas .faq-licor {
    position: absolute;
    left: -10%;
    bottom: -2%;
    width: 20%;
    transform: rotate(17deg);
  }

  .content-frutas .faq-naranja {
    position: absolute;
    right: -2%;
    bottom: -2%;
    width: 20%;
    transform: rotate(17deg);
  }

  .content-frutas .faq-moras {
    position: absolute;
    right: 0%;
    bottom: -7%;
    width: 20%;
    transform: rotate(120deg);
  }

  /*Zonas de cobertura*/
  .mt--5,
  .my--5 {
    margin-top: -3rem !important;
  }
  .zone-menu {
    height: 600px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .zone-menu nav ul li a {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    opacity: 0.7;
    pointer-events: all;
    position: relative;
  }
  .zone-menu nav ul li a:hover {
    text-decoration: none;
    opacity: 0.9;
  }
  .zone-menu nav ul li a.active {
    color: #ec1c24;
    font-weight: 900;
    opacity: 1;
  }

  /*FAQ*/
  .faq_area {
    background-color: #d6cfb0;
    background-image: url(../img/banner_home_JumboPrime.jpg);
    height: 450px;
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-blend-mode: multiply;
  }
  .faq-heading > h2 {
    font-size: 1.5rem;
    color: #ffffff;
    font-weight: 300;
    position: relative;
    z-index: 3;
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: -1px;
    text-align: center;
  }
  .faq-heading > h2 span {
    font-size: 4rem;
    font-weight: 700;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
  }

  .faq-contenedor {
    display: flex;
    padding-bottom: 64px;
    padding-top: 70px;
  }
  .faq-menu {
    display: block;
    padding: 0;
    width: 200px;
  }
  .faq-menu nav {
    position: sticky;
    top: 65px;
  }
  .faq-menu nav ul li a {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    opacity: 0.7;
    pointer-events: all;
    position: relative;
    text-align: right;
  }
  .faq-menu nav ul li a:hover {
    text-decoration: none;
    opacity: 0.9;
  }
  .faq-menu nav ul li a.active {
    color: #ec1c24;
    font-weight: 900;
    opacity: 1;
  }
  .faq-body {
    display: block;
    margin-left: 40px;
    margin-right: 140px;
    padding: 0 16px;
    width: calc(100% - 230px);
  }

  .titulo_faq h3 {
    cursor: none;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 32px;
    color: #383838;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
  }
  .titulo_faq h4 {
    cursor: none;
    font-size: 24px;
    font-weight: 600;
    margin: 32px 0;
    color: #383838;
    font-family: 'isidorasemibold', 'Source Sans Pro', sans-serif;
  }
  .text_faq {
    font-size: 16px;
    line-height: 150%;
    text-align: justify;
    text-align-last: left;
    margin-bottom: 60px;
    color: #333;
    opacity: 0.7;
  }
  .seccion-pregunta:not(:first-child) {
    padding-top: 35px;
  }

  .seccion-pregunta {
    margin-bottom: 65px;
  }
  /*--faq index--*/
  .bg-prime {
    background-color: #0053c0;
  }
  .bg-prime2 {
    background-color: #f5f9fc;
  }
  #faq-section .btn-link,
  .btn-link {
    color: #383838;
    opacity: 0.8;
  }
  #faq-section .btn-link:hover,
  .btn-link:hover {
    text-decoration: none;
    opacity: 1;
  }
  #faq-tab-content .accordion > .card {
    border-left: none;
    border-right: none;
    border-radius: 0;
    border-top: none;
    margin-bottom: 5px;
  }

  #faq-tab-content .accordion > .card > .card-header {
    padding: 20px 25px;
    font-size: 1.2rem;
    background-color: #ec1c24;
    border-bottom: none;
  }
  #faq-tab-content .accordion > .card > .card-header a {
    color: #fff;
    display: block;
    font-family: 'isidorasemibold', 'Source Sans Pro', sans-serif;
  }
  #faq-tab-content .accordion > .card > .card-header:hover a {
    opacity: 0.8;
    text-decoration: none;
  }
  #faq-tab-content .accordion > .card .card-body {
    padding: 2rem 1.2em;
  }
  #faq-tab-content .accordion > .card .card-body {
    font-size: 18px;
    line-height: 160%;
    color: #8c8787;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0.05);
  }

  /*listado de beneficios*/
  .list-beneficios li i {
    font-size: 36px;
  }
  .list-beneficios li h5 {
    font-size: 22px;
  }
  /* --------------------------
:: 4.0 Special Area CSS
-------------------------- */

  .single-icon > i {
    font-size: 36px;
    color: #ec1c24;
  }

  .single-special > h4 {
    font-size: 22px;
    color: #5b32b4;
  }

  .single-icon {
    margin-bottom: 25px;
  }

  .single-special > h4 {
    font-size: 22px;
    color: #383838;
    margin-bottom: 15px;
  }

  .single-special {
    border: 1px solid #eff2f6;
    padding: 10px;
    border-radius: 40px;
    -webkit-transition-duration: 800ms;
    -o-transition-duration: 800ms;
    transition-duration: 800ms;
    margin-bottom: 30px;
  }
  .special_description_content {
    text-align: right;
    margin-top: 30px;
  }
  .special_description_content.first {
    text-align: left;
  }
  .special_description_content > h2 {
    color: #383838;
    font-size: 47px;
    margin-bottom: 30px;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
    font-weight: 800;
  }
  .special_description_content > h2 span {
    font-size: 47px;
    display: block;
    color: #ec1c24;
  }
  .special_description_content > p {
    font-size: 21px;
    font-weight: 300;
    line-height: 160%;
  }
  .special_description_content > ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 20px;
  }

  .special_description_content > ul li {
    font-size: 18px;
    font-weight: 300;
    line-height: 170%;
    // margin-bottom: 12px;
    // background: url('../img/list_red.png') no-repeat left center;
    // background-size: 30px;
    // padding-left: 44px;
  }
  .special_description_content > ul li.puntos {
    font-size: 18px;
    font-weight: 300;
    line-height: 170%;
    margin-bottom: 12px;
    background: url('../img/logos/puntos.png') no-repeat left center;
    background-size: 30px;
    padding-left: 44px;
  }

  .special_description_content > ul.puntos {
    list-style: none;
    padding-left: 0px;
    margin-top: 20px;
  }

  .special_description_content > ul.puntos li {
    font-size: 18px;
    font-weight: 300;
    line-height: 170%;
    margin-bottom: 12px;
    background: url('../img/logos/puntos.png') no-repeat left center;
    background-size: 30px;
    padding-left: 44px;
  }

  .special_description_img {
    text-align: center;
  }
  .special_description_img > img {
    width: 100%;
  }

  .single-special:hover {
    -webkit-box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
    box-shadow: 0 10px 90px rgba(0, 0, 0, 0.08);
  }

  .single-special p {
    margin-bottom: 0;
  }

  .app-download-area {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 1;
    flex-basis: 1;
    margin-top: 50px;
  }

  .app-download-btn a {
    /*border: 1px solid #ddd;*/
    background-color: #fb397d;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 30px;
    border-radius: 30px;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .app-download-btn.active a,
  .app-download-btn a:hover {
    border-color: transparent;
    background-color: #fb397d;
    text-decoration: none;
    opacity: 0.8;
  }

  .app-download-btn a i {
    color: #47425d;
    font-size: 24px;
    margin-right: 15px;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  .app-download-btn a p {
    font-size: 16px;
    line-height: 1;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  .app-download-btn a p span {
    font-size: 12px;
    display: block;
  }

  .app-download-btn:first-child {
    margin-right: 20px;
  }

  .app-download-btn a:hover i,
  .app-download-btn.active a i,
  .app-download-btn.active a p,
  .app-download-btn a:hover p {
    color: #fff;
  }

  /*inscripción*/
  .form_inscrip {
    //background-color: #d6cfb0;
    background-image: url(../img/HEADER1.png);
    height: 45vh;
    position: relative;
    z-index: 1;
    /* background-position: center center; */
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
  .formulario-header {
    background-color: #fff;
    padding: 1.75rem 1.25rem;
  }
  .formulario-header h1 {
    font-weight: bold;
    font-size: 23px;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
    color: #383838;
    margin-bottom: 0;
  }
  .form_inscrip .container .row .mx-auto > .card {
    margin-top: 55px;
  }
  .form_inscrip .card.border-primary {
    border-color: #383838 !important;
  }
  .circle-name {
    color: white;
    background-color: #383838;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    line-height: 45px;
    font-size: 10px;
    text-align: center;
    z-index: 999;
    text-transform: uppercase;
    font-size: 18px;
  }
  .visa-mc-cvc-preview {
    background-image: url(../img/tc-visa_master.png);
  }
  .list-medio-pago .list-group-item.active {
    z-index: 2;
    background-color: #fff;
    border-color: #ec1c24;
  }
  .list-medio-pago .list-group-item i {
    opacity: 0;
    position: absolute;
    right: 15px;
    top: 40%;
  }
  .list-medio-pago .list-group-item.active i {
    opacity: 1;
    color: #ec1c24;
  }
  /*setp*/
  .step {
    position: relative;
    padding: 0 1rem 0 3.5rem;
    margin: 2rem 0 0;
    list-style: none;
  }
  .step__item {
    position: relative;
    min-height: 55px;
    counter-increment: list;
    padding-left: 0.5rem;
  }
  .step__item:before {
    content: '';
    position: absolute;
    left: -1.7rem;
    top: 33px;
    height: 60%;
    width: 1px;
    border-left: 1px solid #ddd;
  }
  .step__item:after {
    content: counter(list);
    position: absolute;
    top: 0;
    left: -2.5rem;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #ddd;
    color: #333;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.5rem;
    text-align: center;
    border: 1px solid #fff;
  }
  .step__item:last-child:before {
    border: none;
  }
  .step__title {
    padding: 0.4rem 0 0.5rem;
    margin: 0;
    font-size: 1.5rem;
  }

  .step__info {
    font-size: 14px;
  }

  /*steps end*/
  /*login*/
  .login,
  .image {
    min-height: 100vh;
  }

  .bg-image {
    background-color: #f5b992;
    background-image: url(../img/fondo_login_compress.png);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-image-about {
    background-color: #d6cfb0;
    background-image: url(../img/beneficios-wong-prime-desk.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .login-heading {
    font-weight: 300;
  }

  .btn-login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    border-radius: 2rem;
    background-color: #ec1c24;
    border-color: #ec1c24;
  }
  .btn-login:hover {
    color: #ec1c24;
    background-color: #fff;
    border-color: #ec1c24;
  }
  .btn-login.disabled,
  .btn-login:disabled {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: #ddd;
    cursor: no-drop;
  }
  .btn-login.disabled:hover,
  .btn-login.disabled.focus,
  .btn-login.disabled:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: #ddd;
    cursor: no-drop;
  }

  /*login end*/
  .footer-contact-area {
    background: #f5f9fc;
  }

  .footer-text > p {
    margin-bottom: 50px;
  }
  .footer-menu ul {
    padding: 0;
  }

  /*---------------------------
::error 404
---------------------------*/
  #notfound {
    position: relative;
    height: 100vh;
  }

  #notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .notfound {
    max-width: 410px;
    width: 100%;
    text-align: center;
  }

  .notfound .notfound-404 {
    height: 280px;
    position: relative;
    z-index: -1;
  }

  .notfound .notfound-404 h1 {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 230px;
    color: #383838;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    /*  background: url('../img/bg-login.png') no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;*/
  }

  .notfound h2 {
    font-family: 'Source Sans Pro', sans-serif;
    color: #545759;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
  }

  .notfound p {
    font-family: 'Source Sans Pro', sans-serif;
    color: #545759;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    margin-top: 0px;
  }

  /* --------------------------
:: Footer
-------------------------- */

  .footer-text > h2 {
    color: #5b32b4;
    font-size: 72px;
    font-weight: 700;
    font-family: 'isidorabold', 'Source Sans Pro', sans-serif;
  }

  .footer-social-icon > a > i {
    background: #9b69e4;
    font-size: 14px;
    color: #ffffff;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 10px 10px 10px 0px;
    margin-right: 10px;
  }

  .footer-social-icon > a > i:hover {
    background: #fb397d;
    color: #fff;
    -webkit-transition-duration: 500ms;
    -o-transition-duration: 500ms;
    transition-duration: 500ms;
  }

  .social-icon {
    margin-bottom: 30px;
  }

  .footer-text {
    margin-bottom: 15px;
  }

  .footer-menu ul li {
    display: inline-block;
  }

  .footer-menu ul li a {
    border-right: 2px solid #726a84;
    display: block;
    padding: 0 7.5px;
    color: #726a84;
  }

  .footer-social-icon > a .active {
    background: #fb397d;
  }

  .footer-menu ul li:last-child a {
    border-right: 0 solid #ddd;
  }

  .footer-social-icon {
    margin-bottom: 30px;
  }

  .footer-menu {
    margin-bottom: 15px;
  }

  .copyright-text > p {
    margin-bottom: 0;
  }

  #footer {
    color: #f5f5f5;
    font-size: 14px;
    background: #00001e;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  }
  #footer .footer-bottom {
    background-color: #383838;
    padding: 15px 0;
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #ec1c24;
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
    text-align: right;
  }
  #footer .footer-top .footer-contact h3 {
    font-size: 28px;
    margin: 0 0 10px 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    color: #f5f5f5;
  }
  #footer .footer-top .footer-contact .logo-footer {
    margin: 0 0 20px 0;
    padding: 0;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-weight: 300;
    color: #f5f5f5;
  }
  #footer .footer-top .footer-contact ul a {
    opacity: 0.8;
  }
  #footer .footer-top .footer-contact ul a:hover {
    opacity: 1;
    cursor: pointer;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top h4 {
    font-size: 18px;
    position: relative;
    padding-bottom: 12px;
  }

  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 3px;
    color: #fff;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #f5f5f5;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    opacity: 0.8;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    opacity: 1;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-newsletter {
    font-size: 15px;
  }
  #footer .footer-bottom .copyright {
    color: #fff;
    opacity: 0.6;
    font-weight: 400;
  }

  // promocion
  .main_promotion {
    // background-color: #d6cfb0;
    background-image: url(../img/HEADER1.png);
    height: 45vh;
    position: relative;
    z-index: 1;
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
  }
  .image_card {
    background-color: rgb(225, 253, 252);
    border-radius: 10px;
    padding-right: 5px;
    padding-left: 5px;
    box-shadow: 8px 9px 9px -8px rgba(0, 85, 153, 0.75);
    -webkit-box-shadow: 8px 9px 9px -8px rgba(0, 85, 153, 0.75);
    -moz-box-shadow: 8px 9px 9px -8px rgba(0, 85, 153, 0.75);
  }
  .promotion_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .promotion_content {
    border-radius: 8px;
    // background-color: rgb(255, 255, 255, 0.87);
  }
  .promotion_info-img {
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  .promotion_info h2 {
    font-family: 'isidoramedium';
    font-size: 34px;
    color: #3a3837;
    line-height: 1;
  }
  .promotion_info h2 span {
    font-family: 'isidorabold';
    font-size: 54px;
  }
  .promotion_info p {
    font-size: 22px;
    margin-bottom: 0;
    color: #3a3837;
  }
  .promotion-end_message {
    padding-top: 50px;
  }
  .promotion_title {
    padding-left: 0;
  }
  .main_promotion .row .page-footer {
    width: 100%;
  }
  .main_promotion .main_promotion-content {
    width: 75%;
  }
  .benefits_list li {
    height: 45px;
    font-size: 18px;
    line-height: 1;
    font-weight: 400;
    padding-top: 7px;
    margin-top: 15px;
    background: url(../img/list_3.png) no-repeat left center;
    background-size: 30px;
    padding-left: 50px;
    vertical-align: middle;
  }

  .table-primax-mobile {
    display: block;
  }
  .table-primax-desktop {
    display: none;
  }

  @media (min-width: 575px) {
    .table-primax-mobile {
      display: none;
    }
    .table-primax-desktop {
      display: block;
    }
  }
  @media (max-width: 575px) {
    .main_promotion .main_promotion-content {
      width: 95%;
    }
    .promotion_content {
      max-width: 90% !important;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .menu_area .navbar-brand {
      font-size: 42px;
    }
    .menu_area #nav .nav-link {
      padding: 35px 7.5px;
    }
    .sing-up-button > a {
      color: #fff;
      height: 40px;
      min-width: 152px;
      line-height: 36px;
      font-size: 14px;
    }
    .welcome-thumb {
      width: 50%;
      bottom: -100px;
      right: 50px;
    }
    .video-area {
      height: 550px;
    }
    .app_screenshots_slides {
      padding: 0 4%;
    }
    .header_area.sticky .menu_area #nav .nav-link {
      padding: 23px 7.5px;
    }
    .wellcome_area {
      background-color: #d6cfb0;
      /*background-image: url(../img/bg-registro-2.png), url(../img/bg-darker.png), url(../img/bg-registro-sm.png);*/
      height: 100vh;
      position: relative;
      z-index: 1;
      background-position: 95% 50%, center center, 200px 82px;
      background-repeat: no-repeat, repeat, no-repeat;
      background-size: cover;
      background-blend-mode: normal, normal, multiply;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .header_area {
      padding: 0;
    }
    .menu_area .navbar-brand {
      font-size: 48px;
    }
    .header_area.sticky {
      height: 50px;
    }
    .header_area .menu_area #nav .nav-link,
    .header_area.sticky .menu_area #nav .nav-link {
      padding: 7.5px 15px;
      color: #fff;
    }
    #ca-navbar {
      padding: 30px;
      border-radius: 3px;
      background-color: rgba(56, 56, 56, 0.95);
      text-align: left;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-top: 7px;
      border-radius: 0 0 4px 4px;
    }
    .sing-up-button {
      margin-top: 30px;
      text-align: left;
    }
    .welcome-thumb {
      width: 50%;
      bottom: -100px;
      right: 0;
    }
    .wellcome-heading {
      margin-bottom: 70px;
    }
    .single-special {
      padding: 30px 10px;
    }
    .special_description_area.mt-150 {
      margin-top: 50px;
    }
    .special_description_content > h2 {
      font-size: 30px;
    }
    .video-area {
      height: 350px;
    }
    .section-heading > h2 {
      font-size: 38px;
    }
    .address-text > p,
    .phone-text > p,
    .email-text > p {
      font-size: 16px;
    }
    .footer-text > p {
      margin-bottom: 30px;
    }
    .section-heading {
      margin-bottom: 50px;
    }
    .sing-up-button > a {
      margin-top: 4px;
      display: inline-block;
      border: 2px solid #a883e9;
      height: 40px;
      min-width: 160px;
      line-height: 36px;
      font-size: 14px;
    }
    .counter-area {
      min-width: 50px;
    }
    .counter-area > h3 {
      font-size: 30px;
    }
    .app_screenshots_slides {
      padding: 0;
    }
    .clients-feedback-area .slick-slide.slick-current img {
      width: 90px !important;
      height: 90px !important;
    }
    .wellcome_area {
      background-color: #d6cfb0;
      /*background-image: url(../img/bg-registro-2.png), url(../img/bg-darker.png), url(../img/bg-registro-sm.png);*/
      height: 100vh;
      position: relative;
      z-index: 1;
      background-position: 100% 50%, center center, 30% 60px;
      background-repeat: no-repeat, repeat, no-repeat;
      background-size: cover;
      background-blend-mode: normal, normal, multiply;
    }
    .secction-2 {
      background-size: 500px;
      background-position: -100px 45%;
    }
    .secction-3 {
      background-size: 650px;
      background-position: -440px -30%;
    }
  }

  @media (min-width: 500px) and (max-width: 1440px) {
    .bg-image-about {
      background-color: #f4e7eb;
      background-image: url(../img/beneficios-wong-prime-desk.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    .header_area {
      padding: 0;
    }
    .menu_area .navbar-brand {
      font-size: 48px;
    }
    .header_area.sticky {
      height: 60px;
    }
    .header_area .menu_area #nav .nav-link,
    .header_area.sticky .menu_area #nav .nav-link {
      padding: 5px 15px;
      color: #fff;
    }
    #ca-navbar {
      padding: 20px;
      border-radius: 3px;
      background-color: rgba(56, 56, 56, 0.95);
      text-align: left;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      margin-top: 7px;
      border-radius: 0 0 4px 4px;
    }
    #ca-navbar .navbar-light .navbar-toggler-icon {
      color: #383838;
    }
    .sing-up-button {
      margin-top: 30px;
      text-align: left;
    }
    .header_area {
      top: 0;
    }
    .welcome_text .cd-intro > p {
      font-size: 14px;
    }
    .wellcome-heading > h2 {
      font-size: 32px;
    }
    .wellcome-heading > h2 span {
      font-size: 3.2rem;
    }
    .get-start-area .email {
      margin-bottom: 20px;
      max-width: 100%;
    }
    .welcome-thumb {
      width: 90%;
      bottom: -74px;
      right: 0;
    }
    .logo_area > a > h2 {
      font-size: 40px;
      margin-top: 10px;
    }
    .section-heading > h2 {
      font-size: 32px;
    }
    .single-special {
      margin-bottom: 30px;
    }
    .special_description_area.mt-150 {
      margin-top: 50px;
    }
    .special_description_content > h2 {
      font-size: 30px;
    }
    .app-download-btn:first-child {
      margin-right: 0;
    }
    .app-download-area {
      display: block;
    }
    .app-download-btn {
      margin-bottom: 20px;
    }
    .video-area {
      border: 10px solid #fff;
      border-radius: 20px;
      height: 250px;
    }
    .video-area::after {
      top: -10px;
      left: -10px;
      width: calc(100% + 20px);
      height: calc(100% + 20px);
      border: 1px solid #f1f4f8;
      z-index: 2;
      border-radius: 20px;
    }
    .clients-feedback-area .client-description > p {
      font-size: 20px;
    }
    .clients-feedback-area .slick-slide img {
      width: 60px !important;
      height: 60px !important;
    }
    .clients-feedback-area .slick-slide.slick-current img {
      width: 70px !important;
      height: 70px !important;
    }
    .membership-description > h2 {
      font-size: 32px;
    }
    .get-started-button {
      text-align: left;
      margin-top: 20px;
    }
    .contact_from {
      margin-top: 30px;
    }
    .wellcome-heading > h3 {
      font-size: 252px;
      top: -105px;
      left: -1px;
    }
    .wellcome-heading,
    .single-cool-fact {
      margin-bottom: 50px;
    }
    .cool_facts_area .col-12:last-of-type .single-cool-fact {
      margin-bottom: 0;
    }
    #footer .footer-top h4 {
      text-align: center;
    }
    #footer .footer-top .footer-links ul li {
      text-align: center;
      display: block;
    }
    #footer .footer-top .footer-contact {
      text-align: center;
    }
    // .form_inscrip {
    //   background-position: center 30px;
    // }
    .notfound .notfound-404 h1 {
      font: 81px;
    }
    .card-body-confirm {
      padding: 1rem !important;
    }
    .wellcome_area {
      background-color: #d6cfb0;
      background-image: url(../img/HEADER5-MOVIL.png);
      //background-image: url(../img/header-mobile-anniversay.png);
      //background-image: url(../img/bg-registro-2.png), url(../img/bg-darker.png), url(../img/bg-registro-sm.png);
      height: 100vh;
      position: relative;
      z-index: 1;
      background-position: 95% 95%, center center, 150px 145px;
      background-repeat: no-repeat, repeat, no-repeat;
      background-size: cover;
      background-blend-mode: normal, normal, multiply;
    }
  }

  @media (min-width: 480px) and (max-width: 767px) {
    .wellcome-heading > h3 {
      font-size: 284px;
    }
    /*.wellcome-heading > h2 {
        font-size: 70px;
    }*/
    .welcome-thumb {
      bottom: -109px;
      width: 75%;
    }
    .wellcome-heading {
      margin-bottom: 50px;
    }
    .wellcome-heading > h3 {
      top: -139px;
    }
    .get-start-area .email {
      max-width: 370px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .wellcome-heading > h3 {
      font-size: 286px;
    }
    .get-start-area .email {
      margin-bottom: 0;
    }
    .get-start-area .submit {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .faq-menu {
      display: none;
    }
    .faq-body {
      width: 100%;
      margin-right: 30px;
      margin-left: 30px;
    }
  }
  @media (min-width: 280px) and (max-width: 479px) {
    .background-frutas {
      background-color: #f8f6f1 !important;
      background-image: none !important;
      //background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
      //background-size: 310px, 310px, 310px, 310px, 310px, 310px, 310px;
      //background-size: 30%, 30%, 30%, 30%, 30%, 30%, 30%;
      //background-position: -18% -9%, 118% -5%, -26% 110%, -34% 94%, 114% 110%, 115% 99%, 119% 84%;
      //background-blend-mode: unset;
    }

    .wellcome_area {
      // background-color: #d6cfb0;
      //background-image: url(../img/header-mobile-anniversay.png);
      background-image: url(../img/HEADER5-MOVIL.png);
      height: 100vh;
      position: relative;
      z-index: 1;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-blend-mode: normal, multiply;
    }
    .wellcome_area .wellcome-heading {
      padding-top: 100px;
      width: 285px !important;
    }
    .wellcome-heading > h2 span {
      font-size: 2rem;
    }
    .secction-2 {
      background-color: #f8f6f1;
      background-image: url(../img/bg-06.png);
      background-size: 300px;
      background-repeat: no-repeat;
      background-position: -155px 69%;
      background-attachment: fixed;
      background-blend-mode: multiply;
    }
    .secction-3 {
      background-size: 600px;
      background-position: -440px -30%;
    }

    .secction-4 {
      background-image: url(../img/FONDO.png);
      background-repeat: no-repeat;
      /* background-attachment: fixed; */
      background-blend-mode: unset;
      background-size: cover;
      background-repeat: round;
    }

    .logo_wong_step {
      width: 60% !important;
    }

    .logo-nav-subscription {
      width: 23% !important;
      position: absolute;
      left: 7%;
    }

    .doc_type {
      display: block !important;
    }

    .page_area {
      background-color: #d6cfb0;
      background-image: url(../img/HEADER5-MOVIL.png);
      height: 450px;
      position: relative;
      z-index: 1;
      //background-position: center center;
      background-size: cover;
      background-position-y: -146%;
      //background-blend-mode: multiply;
    }
  }
  @media (max-width: 1400px) {
    .promotion_info h2 {
      font-size: 28px;
    }
    .promotion_info h2 span {
      font-size: 46px;
    }
    .promotion_info p {
      font-size: 16px;
    }
  }
  @media (max-width: 1216px) {
    .promotion_info h2 {
      font-size: 24px;
    }
    .promotion_info h2 span {
      font-size: 40px;
    }
  }
  @media (max-width: 1042px) {
    .promotion_info h2 {
      font-size: 18x;
    }
    .promotion_info h2 span {
      font-size: 36px;
    }
    .promotion_info p {
      font-size: 14px;
    }
  }
  @media (max-width: 991px) {
    .promotion_info h2 {
      font-size: 24x;
    }
    .promotion_info h2 span {
      font-size: 46px;
    }
    .promotion_info p {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    .promotion_info h2 {
      font-size: 16x;
    }
    .promotion_info h2 span {
      font-size: 32px;
    }
    .promotion_info p {
      font-size: 14px;
    }
  }

  @media (max-width: 575px) {
    .promotion_info h2 {
      font-size: 18px;
    }
    .promotion_info h2 span {
      font-size: 26px;
    }
    .promotion_info p {
      font-size: 13px;
    }
    .benefits_list li {
      font-size: 14px;
    }
  }

  @media (max-width: 460px) {
    .promotion_info h2 {
      font-size: 14px;
    }
    .promotion_info h2 span {
      font-size: 19px;
    }
    .promotion_info p {
      font-size: 11px;
    }
  }

  @media (max-width: 330px) {
    .promotion_info h2 {
      font-size: 10px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    .promotion_info h2 span {
      font-size: 15px;
    }
    .promotion_info p {
      font-size: 7px;
    }
    .benefits_list li {
      font-size: 12px;
    }
  }

  .lh-1-3 {
    line-height: 1.3;
  }

  .react-date-picker__wrapper {
    border: none !important;
  }

  .loading-pay {
    position: fixed;
    width: 100%;
    height: 100%;
    margin: 0;
    left: 0;
    overflow: hidden;
    top: 0;
    background-color: rgb(0 0 0 / 41%);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(4px);
  }

  .table-scroll {
    overflow: auto;
  }
  // Banner-Promotion
  // .banner-promotion-register{
  //   background-color: #f3f3f3;
  //   display: flex;
  //   justify-content: center;
  // }
  // .banner-promotion{
  //   background-color: #f3f3f3;
  //   height: auto;
  //   position: fixed;
  //   width: 100%;
  //   z-index: 300;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   flex-wrap: wrap;
  //   min-height: 50px;
  // }
  // .banner-promotion-web{
  //   display: block;
  //   max-height: 50px;
  //   width: fit-content;
  // }
  // .banner-promotion-mobile{
  //   display: none;

  // }

  // .banner-promotion.header_area.sticky{
  //   position: fixed;
  // }
  // .header_area.sticky {
  //   top: 50px;
  // }
  // .header_area {
  //   top: 45px;
  // }

  // @media (max-width:767px) {
  //   .banner-promotion{
  //     min-height: 90px;
  //   }
  //   .banner-promotion-web{
  //     display: none;
  //   }
  //   .banner-promotion-mobile{
  //     display: block;
  //   }
  //   .header_area{
  //     top: 90px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 90px;
  //   }

  // }
  // @media (max-width:650px) {
  //   .banner-promotion{
  //     min-height: 75px;
  //   }
  //   .header_area{
  //     top: 75px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 75px;
  //   }

  // }
  // @media (max-width:550px) {
  //   .banner-promotion{
  //     min-height: 65px;
  //   }
  //   .header_area{
  //     top: 65px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 65px;
  //   }

  // }

  // @media (max-width:450px) {
  //   .banner-promotion{
  //     min-height: 53px;
  //   }
  //   .header_area{
  //     top: 53px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 53px;
  //   }

  // }

  // @media (max-width:400px) {
  //   .banner-promotion{
  //     min-height: 48px;
  //   }
  //   .header_area{
  //     top: 48px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 48px;
  //   }
  //   // .header_area{
  //   //   top: 40px;
  //   // }
  // }

  // @media (max-width:320px) {
  //   .banner-promotion{
  //     min-height: 38px;
  //   }
  //   .header_area{
  //     top: 38px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 38px;
  //   }
  //   // .header_area{
  //   //   top: 40px;
  //   // }
  // }
  // @media (min-width:768px) {
  //   .banner-promotion{
  //     min-height: 29px;
  //   }
  //   .header_area{
  //     top: 29px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 29px;
  //   }

  // }
  // @media (min-width:930px) {
  //   .banner-promotion{
  //     min-height: 40px;
  //   }
  //   .header_area{
  //     top: 40px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 40px;
  //   }

  // }

  // @media (min-width:1320px) {
  //   .banner-promotion{
  //     min-height: 55px;
  //   }
  //   .header_area{
  //     top: 55px;
  //   }
  //   .header_area.header_area.sticky{
  //     top: 55px;
  //   }
  // }
  // Fin Banner-Promotion
}
